<template>
    <b-container>
        <b-row>
            <b-col cols="12">
                <p class="txt_secondary text-uppercase mt-3"><strong>ANTES DE QUE EL PACIENTE SALGA DE QUIRÓFANO</strong></p>
                <p class="txt_secondary">Enfermería confirma verbalmente con el equipo</p>
            </b-col>
            <b-col class="mt-2" cols="12">
                <b-row>
                    <b-col class="d-flex" cols="12">
                        <b-form-checkbox size="sm" />
                        <span class="size-span">
                            Nombre del procedimento realizado
                        </span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="d-flex" cols="12">
                        <b-form-checkbox size="sm" />
                        <span class="size-span">
                            Recuento de instrumental, material textil y punzocortante
                        </span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="d-flex" cols="12">
                        <b-form-checkbox size="sm" />
                        <span class="size-span">
                            Completo
                        </span>
                    </b-col>
                    <b-col class="mt-2" cols="12">
                        <b-form-input autocomplete="off" type="text" class="txtArea_border" size="sm" />
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col class="d-flex" cols="12">
                        <b-form-checkbox size="sm" />
                        <span class="size-span">
                            No procede conteo
                        </span>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col cols="12">
                <p class="txt_secondary">Etiquetado de las muestras de patología</p>
            </b-col>
            <b-col class="mt-2" cols="12">
                <b-row>
                    <b-col class="d-flex" cols="12" sm="12" md="4" lg="3">
                        <b-form-checkbox size="sm" />
                        <span class="size-span">
                            Procede
                        </span>
                    </b-col>
                    <b-col class="d-flex" cols="12" sm="12" md="4" lg="3">
                        <b-form-checkbox size="sm" />
                        <span class="size-span">
                            No procede
                        </span>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col cols="12">
                <p class="txt_secondary">Registro de incidencia de mal funcionamiento de algún equipo</p>
            </b-col>
            <b-col class="mt-2" cols="12">
                <b-row>
                    <b-col class="d-flex" cols="12" sm="12" md="4" lg="3">
                        <b-form-checkbox size="sm" />
                        <span class="size-span">
                            Procede
                        </span>
                    </b-col>
                    <b-col class="d-flex" cols="12" sm="12" md="4" lg="3">
                        <b-form-checkbox size="sm" />
                        <span class="size-span">
                            No procede
                        </span>
                    </b-col>
                    <b-col class="mt-2" cols="12">
                        <span class="size-span">¿Cuál?</span>
                    </b-col>
                    <b-col class="mt-2" cols="12">
                        <b-form-input autocomplete="off" type="text" class="txtArea_border" size="sm" />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col cols="12">
                <b-row>
                    <b-col cols="12" sm="12" md="12" lg="12">
                        <span class="size-span">Cirujano, anestesiólogo y personal de enfermería revisan los principales puntos para la óptima recuperación del paciente, asi como su tratamiento.</span>
                    </b-col>
                    <b-col class="mt-2" cols="12">
                        <b-row>
                            <b-col cols="12" sm="12" md="3" lg="2">
                                <b-form-checkbox size="sm">
                                    <small class="txt_gray">Si</small>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" sm="12" md="3" lg="2">
                                <b-form-checkbox size="sm">
                                    <small class="txt_gray">No</small>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col cols="12">
                <p class="txt_secondary">Equipo quirúrgico</p>
            </b-col>
            <b-col cols="12">
                <b-table-simple class="table-sm">
                    <b-tbody> 
                        <b-tr>
                            <b-th class="th_style">Cirujano:</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr> 
                        <b-tr>
                            <b-th class="th_style">Anestesiólogo:</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style">1er. ayudante:</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style">2do. ayudante:</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style">Instrumentista:</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style">Otros:</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style">Circulante:</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    data:() => ({

    })
}
</script>